const OvelShapeLoading = () => {
  return (
    <div className="grid place-items-center">
      <div className="relative">
        <div className="size-24 rounded-full border-t-8 border-b-8 border-gray-200"></div>
        <div className="absolute top-0 left-0 size-24 rounded-full border-t-8 border-b-8 border-blue-500 animate-spin"></div>
      </div>
    </div>
  );
};

export default OvelShapeLoading;
