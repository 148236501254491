import { twMerge as cn } from "tailwind-merge";
import { EncryptStorage } from "encrypt-storage";

// secret
const encryptStorage = new EncryptStorage(
  "secret-key-value$2a$12$2j2w1AbCuWPCBobkqsU7gei68X5VsqY19iJoaSrs.ZG0pD3GQGkku",
  {
    storageType: "localStorage",
    encAlgorithm: "Rabbit",
  }
);

const URL_API = import.meta.env.VITE_URL_SSO;

export { cn, encryptStorage, URL_API };
