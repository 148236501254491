import { create } from "zustand";
import { persist } from "zustand/middleware";
import { encryptStorage } from "../helpers";

function storeTheme(set) {
  return {
    theme: "light",
    setTheme: (val) => set((state) => ({ ...state, theme: val })),
  };
}

function storeColor(set) {
  return {
    color: "color-blue",
    setColor: (val) => set((state) => ({ ...state, color: val })),
  };
}

function storeName(set) {
  return {
    name: "",
    setName: (val) => set((state) => ({ ...state, name: val })),
  };
}

function storeDialog(set) {
  return {
    data: {},
    open: false,
    updateOpen: false,
    addOpen: false,
    resetOpen: false,
    setDialog: (val) =>
      set((state) => ({
        ...state,
        data: val?.data,
        open: val?.open,
        updateOpen: val?.updateOpen,
        addOpen: val?.addOpen,
        resetOpen: val?.resetOpen,
      })),
  };
}

const useTheme = create(
  persist(storeTheme, {
    name: "ssoTheme",
    storage: encryptStorage,
    partialize: (state) => ({ theme: state.theme }),
  })
);

const useColor = create(
  persist(storeColor, {
    name: "ssoColor",
    storage: encryptStorage,
    partialize: (state) => ({ color: state.color }),
  })
);

const useName = create(
  persist(storeName, {
    name: "ssoName",
    storage: encryptStorage,
    partialize: (state) => ({ name: state.name }),
  })
);

const useDialog = create(storeDialog);

export { useTheme, useDialog, useName, useColor };
