import { lazy } from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
// import { encryptStorage } from "./helpers";
import Cookies from "js-cookie";
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Applications = lazy(() => import("./pages/Applications"));
const Permissions = lazy(() => import("./pages/Permissions"));
const CreatePermission = lazy(() => import("./pages/Permissions/CreatePermission"));
const UpdatePermission = lazy(() => import("./pages/Permissions/UpdatePermission"));
const Roles = lazy(() => import("./pages/Roles"));
const Users = lazy(() => import("./pages/Users"));
const Profile = lazy(() => import("./pages/Profile"));
const Login = lazy(() => import("./pages/Login"));
const Layout = lazy(() => import("./components/Layout"));
const FormRole = lazy(() => import("./pages/Roles/form"));
const Companies = lazy(() => import("./pages/Companies"));
const FormUser = lazy(() => import("./pages/Users/form"));

const Authenticated = ({ component }) => {
  const auth = Cookies.get("sso_refresh_token");

  if (!auth) {
    return <Navigate to="/login" />;
  }

  return component;
};

const Router = createBrowserRouter([
  {
    path: "/",
    element: <Authenticated component={<Layout />} />,
    children: [
      { path: "/", element: <Dashboard /> },
      { path: "/apps", element: <Applications /> },
      { path: "/permissions", element: <Permissions /> },
      { path: "/permission/create", element: <CreatePermission /> },
      { path: "/permission/update", element: <UpdatePermission /> },
      { path: "/roles", element: <Roles /> },
      { path: "/role/create", element: <FormRole /> },
      { path: "/role/update", element: <FormRole isEdit /> },
      { path: "/companies", element: <Companies /> },
      { path: "/users", element: <Users /> },
      { path: "/user/create", element: <FormUser /> },
      { path: "/user/update", element: <FormUser isEdit /> },
      { path: "/profile", element: <Profile /> },
    ],
  },
  { path: "/login", element: <Login /> },
]);

export { Router };
